import React, {useCallback, useMemo} from 'react';
import styles from './Membership.module.scss';
import {Button} from '../Button';
import {storeGeneral} from '../../Store/General';
import {observer} from 'mobx-react-lite';

export const Membership = observer(({onClick = () => {}, item}) => {
  const _onClick = useCallback(() => {
    onClick(item.id);
  }, [item, onClick]);
  let classesContainer = useMemo(
    () =>
      [
        styles.container,
        storeGeneral.membership.activeID === item.id
          ? styles.containerActive
          : [],
      ].join(' '),
    [item],
  );

  return (
    <div className={classesContainer} onClick={_onClick}>
      <div className={styles.header}>
        <div className={styles.name}>{item.name}</div>
        <div className={styles.newPrice}>
          ${item.newPrice.total}{' '}
          <span className={styles.newPricePeriod}>/ bi-weekly</span>
        </div>
      </div>
      <div className={styles.content}>
        {(() => {
          if (storeGeneral.city === 'wpg') {
            return (
              <div className={styles.oldPrice}>
                <div className={styles.oldPriceMain}>
                  Regular ${item.oldPrice.total}
                </div>
                <div>+ ${item.oldPrice.enrolment} Initiation fee</div>
                {
                  item.oldPrice.roadMapSession > 0
                  ?
                  <div>+ ${item.oldPrice.roadMapSession} Road Map Session fee</div>
                  :
                  ''
                }
              </div>
            )
          }
        })()}
        <div className={styles.conditions}>
          {
            item.emphasis.length > 0
            ?
              <div className={styles.conditionItem}>
                <div dangerouslySetInnerHTML={{__html: item.emphasis}} />
              </div>
            :
            ''
          }
          {
            item.newPrice.roadMapSession > 0
            ?
              <div className={styles.conditionItem}>
                ${item.newPrice.roadMapSession} Road Map Session fee
              </div>
            :
            ''
          }
          {
            ((item.newPrice.roadMapSession === 0) && (storeGeneral.city === 'wpg'))
            ?
              <div className={styles.conditionItem}>
                Complimentary Road Map Session
              </div>
            :
            ''
          }
          {item.additionalInfo.map((info, index) => (
            <div className={styles.conditionItem} key={index}>
              <div dangerouslySetInnerHTML={{__html: info}} />
            </div>
          ))}
        </div>
        <Button text="choose plan" className={styles.button} />
      </div>
    </div>
  );
});

import React from 'react';
import styles from './Header.module.scss';
import {ReactComponent as IconLogo} from '../../icons/altea-v2.svg';
import {storeGeneral} from '../../Store/General';

export function Header() {
  //let lvStayInTouch = 'https://docs.google.com/forms/d/e/1FAIpQLSckIY4Y-KgptiXcCOhSo5b-7GUTzuon-j7TjVbmYsaD-G-8Dg/viewform?embedded=true';
  //let wpgStayInTouch = 'https://docs.google.com/forms/d/e/1FAIpQLScLApEMexVcF-nQUG-LEA2gwBZDws1BX3ALDk9WTsirrvieMA/viewform?embedded=true';

  //let wpgMemQs = 'https://docs.google.com/forms/d/e/1FAIpQLSeg4fO6nKYlLwDOeynDE-LO65mzw5-1BhQYSXzGSULWzNDE0g/viewform?embedded=true';
  //let lvMemQs = 'https://docs.google.com/forms/d/e/1FAIpQLSeBIy5CzodtsQJ0PtDWRSjRcAd89PSuexSoJUsJljLoUdeQKg/viewform?embedded=true';

  return (
    <header className={styles.container}>
      <div className={styles.content}>
        <a
          href={'https://portal.alteaactive.com/?club=' + storeGeneral.city}
          className={styles.menuLogo}>
          <IconLogo className={styles.logo} />
        </a>
        <div className={styles.menu}>
          {storeGeneral.city === 'lv' ? 
            <a
              href="https://alteaactive.com/faq/"
              target="_blank"
              rel="noreferrer"
              className={styles.menuItem}>
              FAQs
            </a> : ''
          }
        </div>
      </div>
    </header>
  );
}

export const TAX = {
  lv: 13,
  wpg: 5,
};

export const STUBS_DATA = {
  lv: [
    {
      id: 1,
      name: 'GOLD',
      saveNameAs: 'GOLD OP 20',
      newPrice: {
        total: '99.99',
        enrolment: '199.99',
        roadMapSession: 0,
      },
      oldPrice: {
        total: '96.99',
        enrolment: '299.99',
        roadMapSession: '49.99',
      },
      commitment: '12-Month Commitment',
      emphasis: '',
      additionalInfo: [
        '220+ Weekly Group Classes',
        'Sauna, Steam Room, 25M Lap Pool & Hot Tubs',
        'Two-Hour Parking',
        '<strike>LF3 Small Group Training</strike>',
        '<strike>Multi-Club Access</strike>',
        '<strike>Complimentary Guest Passes</strike>',
        '<strike>Unlimited LF3 LIVE Interactive Online</strike>',
        'Unlimited LF3 On Demand Workouts',
        'Towel Service',
        'Road Map Session',
        '99.99 One-Time Initiation',
        '<span style="font-weight: 300;">No Freeze Privileges</span>',
      ],
      freeBiWeeklies: '0',
    },
    {
      id: 2,
      name: 'DIAMOND',
      saveNameAs: 'DIAMOND OP 20',
      newPrice: {
        total: '134.99',
        enrolment: '99.99',
        roadMapSession: 0,
      },
      oldPrice: {
        total: '184.99',
        enrolment: '299',
        roadMapSession: '49.99',
      },
      commitment: '12-Month Commitment',
      emphasis: '',
      additionalInfo: [
        '220+ Weekly Group Classes',
        'Sauna, Steam Room, 25M Lap Pool & Hot Tubs',
        'Two-Hour Parking',
        '6 Biweekly Reformer Pilates Sessions',
        'Unlimited LF3 Small Group Training',
        '7-Day Booking For LF3 Group Training',
        '7-Day Booking for Reformer Pilates',
        'Multi-Club Access',
        '4 Guest Passes (One-time)',
        'Unlimited LF3 On Demand Workouts',
        'Unlimited LF3 LIVE Interactive Online',
        'Towel Service',
        'LF3 Rx Weekly Workout Plan',
        'Monthly Intelligent Bioscans',
        '99.99 One-Time Initiation',
        '<span style="font-weight: 300;">Freeze Privileges: 12 weeks per 12 Months *2-week increments per freeze request. Requires 5 days advanced notice. 12-month period resets on membership contract date.</span>',
      ],
      freeBiWeeklies: '0',
    },
    {
      id: 3,
      name: 'PLATINUM FLEX',
      saveNameAs: 'PLATINUM FLEX OP 20',
      newPrice: {
        total: '129.99',
        enrolment: '249.99',
        roadMapSession: 0,
      },
      oldPrice: {
        total: '106.99',
        enrolment: '399.99',
        roadMapSession: '49.99',
      },
      commitment: 'No Commitment. Membership may be cancelled anytime with 30-days notice.',
      emphasis: '',
      additionalInfo: [
        '<b>No Commitment</b>',
        '249.99 One-Time Initiation',
        '<span style="font-weight: 300;">Freeze Privileges: 12 weeks per 12 Months *2-week increments per freeze request. Requires 5 days advanced notice. 12-month period resets on membership contract date.</span>', 
      ],
      freeBiWeeklies: '0',
    },
    {
      id: 4,
      name: 'DIAMOND FLEX',
      saveNameAs: 'DIAMOND FLEX OP 20',
      newPrice: {
        total: '159.99',
        enrolment: '249.99',
        roadMapSession: 0,
      },
      oldPrice: {
        total: '184.99',
        enrolment: '299',
        roadMapSession: '49.99',
      },
      commitment: '3-Month Commitment',
      emphasis: '',
      additionalInfo: [
        '<b>3-Month Commitment</b>',
        '249.99 One-Time Initiation',
        '<span style="font-weight: 300;">Freeze Privileges: 12 weeks per 12 Months *2-week increments per freeze request. Requires 5 days advanced notice. 12-month period resets on membership contract date.</span>', 
      ],
      freeBiWeeklies: '0',
    },
  ],
  wpg: [
    {
      id: 5,
      name: 'GOLD',
      saveNameAs: 'GOLD',
      newPrice: {
        total: '40.95',
        enrolment: '69.95',
        roadMapSession: '24.95',
      },
      oldPrice: {
        total: '44.95',
        enrolment: '199',
        roadMapSession: '49.95',
      },
      commitment: '12 month commitment',
      emphasis: '',
      additionalInfo: [
        '12-month commitment',
        'Unlimited Studio Fitness Classes (190+ weekly), including Yoga & Aquatics',
        '$69.95 Initiation Fee',
      ],
      freeBiWeeklies: 0,
    },
    {
      id: 6,
      name: 'PLATINUM',
      saveNameAs: 'PLATINUM',
      newPrice: {
        total: '47.95',
        enrolment: '39.95',
        roadMapSession: '24.95',
      },
      oldPrice: {
        total: '54.95',
        enrolment: '199',
        roadMapSession: '49.95',
      },
      commitment: '12 month commitment',
      emphasis: '',
      additionalInfo: [
        '12-month commitment',
        'Freeze Privileges - up to 6 Biweekly cycles maximum per calendar year',
        'Unlimited Studio Fitness Classes (190+ weekly), including Yoga & Aquatics',
        'Towel Service',
        '$39.95 Initiation Fee',
      ],
      freeBiWeeklies: 0,
    },
    {
      id: 7,
      name: 'GOLD FLEX',
      saveNameAs: 'GOLD FLEX',
      newPrice: {
        total: '50.95',
        enrolment: '109.95',
        roadMapSession: '24.95',
      },
      oldPrice: {
        total: '67.95',
        enrolment: '249',
        roadMapSession: '49.95',
      },
      commitment: 'No commitment',
      emphasis: '',
      additionalInfo: [
        'Unlimited Studio Fitness Classes (190+ weekly), including Yoga & Aquatics',
        '$109.95 Initiation Fee',
      ],
      freeBiWeeklies: 0,
    },
  ],
};

export const SALESPERSON_OPTIONS = {
  lv: [
    {value: 'Eric Baumer', label: 'Eric Baumer'},
    {value: 'Hannah Paola', label: 'Hannah Paola'},
    {value: 'Holly Alieva', label: 'Holly Alieva'},
  ],
  wpg: [
    {value: 'Grayson Collins', label: 'Grayson Collins'},
    {value: 'Izzy Funk', label: 'Izzy Funk'},
    {value: 'Norah Fan', label: 'Norah Fan'},
    {value: 'Paul Geli', label: 'Paul Geli'},
  ],
};
